@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;500&family=Roboto:wght@400;700&display=swap');

#root {
  overflow-x: hidden;
}

*::selection {
  background-color: #ff124f;
  color: #fff;
}

body * {
  transition: all .2s linear;
  -webkit-transition: all .2s linear;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #070A2B;
}

*::-webkit-scrollbar-thumb {
  background-color: #3204EB;
  border-radius: 4px;
}

.App {
  text-align: center;
  position: relative;
  background: radial-gradient(100% 148.46% at 0.35% 0.35%, rgba(12, 19, 79, 0.5) 0%, rgba(9, 13, 53, 0) 100%), radial-gradient(100% 239.32% at 100% 0%, #0C134F 0%, #0C134F 0.01%, #090D35 44.03%, #080C33 75.92%);
  background-attachment: fixed;
}

.landing {
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

img {
  user-select: none;
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: transparent;
  box-sizing: border-box;
  padding: 24px 64px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.3) 50.52%, rgba(0, 0, 0, 0) 100%)
}

.navbar-wrapper>h1 {
  font-family: 'JetBrains Mono', monospace;
  color: #F1EFFF;
  font-weight: 500;
  font-size: 24px;
  margin: 0;
}

.navbar-container {
  float: right;
  padding: 0;
  margin: 0;
}

.navbar-container>ul {
  margin: 0;
  padding: 0;
}

.navbar-container>ul .nav-item {
  display: inline-block;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: #F1EFFF80;
  font-weight: 400;
  padding: 0 22px;
  cursor: pointer;
  line-height: 1.8;
}

.navbar-container>ul .nav-item:hover {
  color: #F1EFFF;
  text-shadow: 0px 0px 8px #F1EFFF88;
}

.active-nav-item {
  color: #F1EFFF !important;
  text-shadow: 0px 0px 8px #F1EFFF88;
  text-decoration: underline;
}

.section {
  height: 100vh;
}

.section>.inline {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.section>.inline.reverse {
  flex-direction: row-reverse;
}

.landing-hero {
  position: relative;
  color: #F1EFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  min-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
}

.landing-about {
  height: 100vh;
  width: 100vw;
  background: radial-gradient(138.09% 162.46% at 100% 0%, #0C134F 19.48%, #090D35 33.85%, #080C33 63.11%, #010104 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 128px;
  box-sizing: border-box;
}

.landing-text {
  width: 100%;
}

.landing-text>h1 {
  font-size: 44px;
  color: #71DCF4;
  font-family: 'JetBrains Mono', monospace;
  text-shadow: 0px 0px 8px #71DCF49d;
}

.landing-text>p {
  color: #F1EBFF;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  line-height: 2.5rem;
}

.landing-illustration>img {
  width: 100%;
  max-height: 500px;
}

.landing-illustration {
  width: 100%;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}


.landing-content {
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
}

.landing-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(4, 3, 11, 0.40);

}

.header-container {
  display: flex;
  flex-direction: column;
  color: #F1EBFF;
  gap: 32px;
  z-index: 2;
  max-width: 70vw;
}

.header-container>.landing-header {
  font-size: 48px;
  font-weight: 500;
  line-height: 4rem;
  letter-spacing: 0.1em;
  text-align: center;
  font-family: 'JetBrains Mono', monospace;
  text-shadow: 0px 0px 8px rgba(241, 235, 255, 0.5);
  margin: 0;
}

.header-container>.landing-subheader {
  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1em;
  margin: 0px;
  color: #f1ebff9d;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.landing-button {
  padding: 12px 32px;
  background-color: #3204EB;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  color: #F1EFFF;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 50px;
  width: 266px;
  position: relative;
  overflow: hidden;
}

.landing-button>span {
  position: absolute;
  right: 32px;
}

.landing-button-icon {
  position: absolute;
  left: 32px;
  width: 28px;
  opacity: 100%;
}

.button-arrow-icon {
  position: absolute;
  right: -42px;
  opacity: 0%;
}

.landing-button:hover>span {
  transform: translate(-30%, 0);
}

.landing-button:hover>.landing-button-icon {
  left: -32px;
  opacity: 0%;
}

.landing-button:hover>.button-arrow-icon {
  right: 32px;
  opacity: 100%;
}

.secondary-button-container {
  position: relative;
}

.secondary-button {
  background-color: transparent;
  border: 0.5px solid #F1EFFF30;
  opacity: 70%;
  padding: 8px 14px;
  font-family: 'Roboto', sans-serif;
  color: #F1EFFF;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown-container {
  display: none;
  position: absolute;
  left: 0;
  margin-top: 12px;
  box-sizing: border-box;
  z-index: 11;
  padding: 32px;
  background-color: #070A2A;
  min-width: 360px;
  box-shadow: 0px 0px 8px #3204EB20;
  gap: 24px;
}

.dropdown-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.dropdown-header-container>h1 {
  color: #F1EBFF;
  font-size: 16px;
  margin: 0;
}

.dropdown-header-container>h1>svg {
  margin-right: 8px;
}

.reports-dropdown-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-family: 'Roboto', sans-serif;
  color: #F1EBFF;
  white-space: nowrap;
}

.reports-dropdown-container>select {
  margin-left: 8px;
}

.reports-dropdown-container>select>option {
  text-transform: capitalize;
}

.patient-report {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-start;
  align-items: center;
}

.report-information {
  margin-top: 16px;
}

.report-information>div {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: flex-start;
}

.filter-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  box-sizing: border-box;
}

.filter-toggle-container>div {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: flex-start;
}

.custom-select-input {
  min-height: 32px;
  margin-right: -36px;
  background-color: #0A0F3E;
  padding: 8px 12px;
  color: #F1EFFF;
  border-radius: 4px;
  text-align: left;
  font-size: 12px;
  border-color: #f1efff50;
}

.custom-select-input>option {
  font-size: 14px;
}

.custom-select-input:focus {
  border-color: #3204EB;
}

.secondary-button>p {
  margin: 0;
}

.secondary-button>.button-icon {
  width: 12px;
}

.secondary-button:hover {
  opacity: 100%;
  border-color: #F1EFFF80;
}

.primary-button {
  background-color: #3204EB;
  border: none;
  padding: 8px 16px;
  font-family: 'Roboto', sans-serif;
  color: #F1EFFF;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.primary-button:hover {
  background-color: #2803bb;
}

.content-header-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 16px;
  padding: 32px 64px;
}

.header-button-wrapper {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.chainAlert {
  background-color: #cc230d;
  opacity: 0.9;
  padding: 8px;
  border-radius: 8px;
  z-index: 9;
}

.profile-container {
  position: relative;
}

.sidepanel-header>.profile-container {
  display: none;
}

.profile-button {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  border: 3px solid #3204EB;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.profile-button:hover {
  box-shadow: 0px 0px 8px #3204EB;
}

.profile-dropdown {
  display: none;
  box-sizing: border-box;
  position: absolute;
  margin-top: 12px;
  z-index: 11;
  padding: 32px;
  background-color: #070A2A;
  right: 0px;
  min-width: 300px;
  box-shadow: 0px 0px 8px #3204EB20;
}

.profile-dropdown>h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #F1EBFF;
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
}

.profile-dropdown svg {
  margin-right: 8px;
}

.profile-dropdown>p {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #F1EBFF60;
  font-weight: 400;
  text-align: start;
  word-break: break-all;
  line-height: 1.6;
  margin: 8px 0;
}

.logout-button {
  border: none;
  opacity: 60%;
  padding: 20px 0 0;
  width: 100%;
  text-align: start;
  background-color: transparent;
  color: #F1EBFF;
  cursor: pointer;
}

.logout-button:hover {
  opacity: 100;
  text-shadow: 0px 0px 8px rgba(241, 239, 255, 0.5);
}

.logout-button>svg {
  margin-right: 8px;
}

.dropdownIsOpen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.profile-dropdown-divider {
  width: 100%;
  height: 1px;
  background-color: #F1EBFF50;
}

.filter-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.header-search-input {
  background-color: transparent;
  border-width: 0 0 1px 0;
  min-height: 30px;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  padding: 2px 4px;
  border-color: #F1EBFF;
  color: #F1EBFF;
  font-size: 14px;
}

.header-search-input:focus {
  outline: none;
  border-color: #3204EB;
}

.header-search-input::placeholder {
  color: #F1EBFFaa;
}

.content-header {
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
}

.demo-wrapper * {
  transition: all .3s linear;
  -webkit-transition: all .3s linear;
}

.demo-wrapper {
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #02000c;
  transition: all .4s;
}

.demo-text-container {
  box-sizing: border-box;
}

.demo-text-container h1 {
  max-width: 530px;
  font-size: 44px;
  margin: 32px auto;
  line-height: 3rem;
  font-family: 'JetBrains Mono', monospace;
  font-weight: lighter;
  color: #F1EBFF;
  text-shadow:
    0 0 4px #F1EBFF,
    0 0 10px #F1EBFF,
    0 0 21px #4314ff,
    0 0 42px #4314ff,
    0 0 82px #4314ff,
    0 0 98px #4314ff;
    animation: pulsate 1.5s infinite alternate;  
}

.demo-text-container>p {
  color: #f1ebffbb;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto 56px;
  max-width: 60%;
  line-height: 1.4rem;
}

.demo-text-container>p>span {
  color: #4314ff;
  font-size: 18px;
}

.demo-text-container>img {
  margin-bottom: 32px;
  width: 144px;
  animation: pulsateImg 1.5s infinite alternate;
}

.demo-animation-container {
  position: absolute;
  width: 200vw;
  height: 200vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  box-sizing: border-box;
}

.demo-animation-container>div .css-b62m3t-container {
  min-width: 50vw;
  border: 1px solid #5126ff;
  border-radius: 8px;
  box-shadow: 0 0 16px #4314ff;
}

.demo-animation-container>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.report-select-type.css-b62m3t-container{
  z-index: 3;
}

.demo-select-container .css-az4kcl-menu {
  color: #F1EBFF;
}

.demo-select-container:nth-of-type(1) {
  position: relative;
  overflow: visible;
}

#line1 {
  height: 4px;
  width: 0;
  position: absolute;
  top: 50%;
  left: 400px;
  background-color: #F1EBFF;
  box-shadow: 0 0 2px #4314ff,
    0 0 4px #4314ff,
    0 0 6px #4314ff,
    0 0 8px #4314ff,
    0 0 10px #4314ff,
    0 0 12px #4314ff,
    0 0 16px #4314ff;
}

#line1.line-slide-right {
  width: 100%;
}

#line2 {
  width: 4px;
  height: 0;
  position: absolute;
  left: 50%;
  top: 40px;
  background-color: #F1EBFF;
  box-shadow: 0 0 2px #4314ff,
    0 0 4px #4314ff,
    0 0 6px #4314ff,
    0 0 8px #4314ff,
    0 0 10px #4314ff,
    0 0 12px #4314ff,
    0 0 16px #4314ff;
}

#line2.line-slide-down {
  height: 900px;
}

.demo-select-container:nth-of-type(2) {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.secondary-button.demo-button {
  padding: 14px 24px;
  border-radius: 8px;
}

.div3 {
  position: relative;
  background-color: #02000c;
  width: 500px;
  height: 400px;
  margin: auto;
  border: 4px solid #F1EBFF;
  box-shadow: 0 0 2px #F1EBFF,
  0 0 4px #F1EBFF,
  0 0 6px #4314ff,
  0 0 8px #4314ff,
  0 0 10px #4314ff,
  0 0 12px #4314ff,
  0 0 16px #4314ff,
  inset 0 0 16px #4314ff;
  border-radius: 48px 160px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 2 / 2 / 3;
}

.div3 {
  grid-area: 2 / 2 / 3 / 3;
}

.slide-right {
  transform: translate(-50%, 0);
}

.slide-down {
  transform: translate(-50%, -50%);
}

#last-box-text {
  max-width: 70%;
}

@keyframes pulsate {
  100% {
    text-shadow:
      0 0 4px #F1EBFF,
      0 0 11px #F1EBFF,
      0 0 19px #4314ff,
      0 0 40px #4314ff,
      0 0 80px #4314ff,
      0 0 90px #4314ff;
  }

  0% {
    text-shadow:
      0 0 2px #F1EBFF,
      0 0 4px #F1EBFF,
      0 0 6px #4314ff,
      0 0 8px #4314ff,
      0 0 32px #4314ff,
      0 0 40px #4314ff;
  }
}

@keyframes pulsateImg {
  100% {
    filter: drop-shadow(0 0 4px #F1EBFF);
    filter: drop-shadow(0 0 8px #F1EBFF);
    filter: drop-shadow(0 0 10px #4314ff);
    filter: drop-shadow(0 0 14px #4314ff);
    filter: drop-shadow(0 0 18px #4314ff);
  }

  0% {
    filter: drop-shadow(0 0 2px #F1EBFF);
    filter: drop-shadow(0 0 4px #F1EBFF);
    filter: drop-shadow(0 0 6px #4314ff);
    filter: drop-shadow(0 0 8px #4314ff);
    filter: drop-shadow(0 0 10px #4314ff);
  }
}


.devices {
  position: relative;
  color: #F1EFFF;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  background-image: url(assets/background/devicesBg.svg);
  background-position: top right;
  background-size: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.devices-background-logo {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 0;
  width: 60%;
}

.scroll-top-button {
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #3204EB;
  border: none;
  z-index: 5;
  cursor: pointer;
  text-align: center;
}

.scroll-top-button:hover {
  background-color: #2803bb;
}

.main-content-wrapper {
  position: relative;
  width: calc(100vw - 260px);
  padding: 96px 64px 32px;
  margin-left: 260px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 84px;
  box-sizing: border-box;
}

.table-scroll {
  max-height: 600px;
  overflow-y: scroll;
}

.content-table {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-top: 44px;
  table-layout: auto;
  border-collapse: collapse;
  font-family: 'Roboto', sans-serif;
}

.parameters-table {
  border-collapse: collapse;
  font-family: 'Roboto', sans-serif;
  width: 100%;
}

.parameters-table .table-body-row th {
  border-bottom: 1px solid rgba(241, 239, 255, 0.2);
  color: #F1EBFF;
  text-align: start;
  font-weight: 600;
  font-size: 14px;
}

.parameters-table .table-body-row td {
  text-align: end;
  font-weight: 400;
  font-size: 14px;
  color: #F1EBFF;
}

.patient-report>.content-table {
  margin-top: 0;
}

.device-box-table {
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-gap: 16px;
}

.table-header-row>th {
  white-space: nowrap;
  text-align: start;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  color: rgba(241, 239, 255, 0.8);
  background-color: rgba(241, 239, 255, 0.1);
}

.table-header-row>th>svg {
  margin-right: 4px;
}

.table-body {
  position: relative;
}

.table-body-row>td {
  text-align: start;
  padding: 16px !important;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  color: #F1EBFF;
  border-bottom: 1px solid rgba(241, 239, 255, 0.2);
  line-height: 1.2rem;
}

.table-body-row>td>p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#doctor-column,
#expert-column {
  white-space: nowrap;
}

#doctor-column>div,
#expert-column>div {
  margin-left: 8px;
}

.table-body-row>td.table-action-buttons {
  white-space: nowrap;
  text-align: center;
}

.table-body-row>td.table-action-buttons>button:not(:last-of-type) {
  margin-right: 8px;
}

.device-item {
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #F1EFFF20;
  position: relative;
  box-sizing: border-box;
  min-height: 88px;
}

.device-item>.main-content>i {
  position: absolute;
  right: 32px;
  top: 42px;
}

.device-item>.main-content>i::before,
.device-item>.main-content>i::after {
  content: "";
  position: absolute;
  background-color: #F1EBFF;
  width: 3px;
  height: 9px;
}

.device-item>.main-content>i::before {
  transform: translate(-2px, 0) rotate(45deg);
}

.device-item>.main-content>i::after {
  transform: translate(2px, 0) rotate(-45deg);
}

.device-item input.accordion-checkbox {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
}

.device-item input.accordion-checkbox:checked~.detail-content {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  opacity: 0;
  transform: translate(0, -10%);
}

.device-item input.accordion-checkbox:checked~.main-content {
  background: transparent;
}

.device-item input.accordion-checkbox:checked~.main-content>i:before {
  transform: translate(2px, 0) rotate(45deg);
}

.device-item input.accordion-checkbox:checked~.main-content>i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

.device-item>.main-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 28px;
  box-sizing: border-box;
  min-height: 88px;
  background: linear-gradient(270deg, rgba(241, 239, 255, 0.0) 0%, rgba(241, 239, 255, 0.06) 30%, rgba(241, 239, 255, 0.0) 100%);
}

.device-item>.detail-content {
  position: relative;
  overflow: hidden;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 14px;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: 36px;
  margin-top: 0;
  box-sizing: border-box;
  border-top: 1px solid #F1EFFF08;
}

.info-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  width: 50%;
}

.info-column:last-of-type {
  align-items: flex-end;
}

.col-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-family: 'Roboto', sans-serif;
}

.main-content>.col-container {
  width: 33%;
}

.main-content>.col-container:last-of-type {
  text-align: end;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.detail-content>.col-container:last-of-type {
  margin-right: 32px;
}

.detail-content>.info-column>#accessStatusMobile {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  max-width: 46px;
  max-height: 22px;
  margin-left: 8px;
  z-index: 4;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: .4s;
  min-width: 44px;
  min-height: 20px;
  border: .5px solid #F1EFFF50;
}

.slider:before {
  position: absolute;
  content: "";
  min-height: 18px;
  min-width: 18px;
  left: 1px;
  bottom: 1.5px;
  background-color: #F1EFFF50;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #FF114F;
  border-color: transparent;
}

input:checked+.slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24.5px);
  background-color: #F1EFFF;
}

/* Rounded sliders */
.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}

.action-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  z-index: 4;
  color: #F1EBFF;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}

#device-box-duration {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
}

.device-box-column#icon-container>svg:nth-of-type(2) {
  display: none;
}

.device-box-column .action-button>svg {
  margin-right: 8px;
}

.device-box-column .action-button:hover,
.action-button>svg:hover {
  filter: drop-shadow(0px 0px 8px #F1EFFFaa);
}

.prop-title {
  color: #F1EFFF;
  font-size: 14px;
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  white-space: nowrap;
}

.prop-content {
  color: #ABA5B7;
  font-size: 14px;
  font-weight: lighter;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  text-align: left;
  width: 100%;
  white-space: nowrap;
  position: relative;
}

.prop-content .badges {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: 4px;
}

.prop-content .badges>div {
  margin-left: 8px;
}

.prop-content>a {
  color: #3204EB;
  text-decoration: none;
}

#copiable {
  cursor: pointer;
}

.sidepanel-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-width: 260px;
  padding: 32px 24px;
  z-index: 4;
  box-sizing: border-box;
  background-color: #070A2B;
}

.sidepanel-header {
  margin-bottom: 96px;
}

.sidepanel-header>h1 {
  font-size: 22px;
  color: #F1EFFF;
  text-align: left;
  font-family: 'JetBrains Mono', monospace;
  margin: 0;
}

.sidepanel-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 28px;
}

.sidepanel-button {
  background: transparent;
  font-family: 'Roboto', sans-serif;
  border: none;
  padding: 0px 24px;
  box-sizing: border-box;
  width: 100%;
  color: #F1EFFF;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  position: relative;
}

.sidepanel-button>p {
  padding: 0;
  margin: 0;
}

.sidepanel-button>svg {
  width: 18px;
}

.sidepanel-button:hover,
.sidepanel-active {
  opacity: 100;
  text-shadow: 0px 0px 8px rgba(241, 239, 255, 0.5);
}

.sidepanel-active {
  border-right: 2px solid #F1EBFF;
}

.sidepanel-inactive {
  opacity: 50%;
}

.sidepanel-notification {
  width: 16px;
  height: 16px;
  background: #FF114F;
  box-shadow: 0px 0px 8px rgba(255, 17, 79, 0.51);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  color: #F1EBFF;
  position: absolute;
  top: -6px;
  right: 38px;
}

.add-device-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 64px;
}

.radio-input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
}

.radio-button {
  color: #F1EBFF;
  cursor: pointer;
}

.radio-button>.badge {
  opacity: 40%;
  font-size: 14px;
}

.radio-button>input[type="radio"] {
  opacity: 0%;
  width: 0px;
  height: 0px;
  margin: 0;
}

.radio-button>input[type="radio"]:hover+div {
  opacity: 100%;
}

.radio-button>input[type="radio"]:checked+div {
  opacity: 100%;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.input-label {
  text-align: left;
  color: #F1EFFF;
  opacity: 70%;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
}

.input-label>svg {
  margin-right: 8px;
}

.input-container>input {
  min-height: 36px;
  background-color: #0A0F3E;
  padding: 2px 12px;
  color: #F1EFFF;
  border: none;
  border-bottom: 1px solid #090D39;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
}

.input-container>input:focus {
  outline: none;
  border-color: #3204EB;
}

.device-box,
.pause-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  box-sizing: border-box;
  width: 100%;
  background: linear-gradient(270deg, rgba(241, 239, 255, 0) 0%, rgba(241, 239, 255, 0.1) 49.47%, rgba(241, 239, 255, 0) 100%);
  border: 1px solid rgba(241, 239, 255, 0.1);
  border-radius: 4px;
  padding: 24px;
}

.pause-box {
  align-items: center;
}

.device-box-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  font-size: 14px;
}

.device-box-column.col1 {
  align-items: flex-start;
}

.device-box-column.col2 {
  align-items: flex-end;
  justify-content: flex-end;
}

.device-box .action-button {
  padding: 0;
}

#user-key {
  opacity: 0.6;
}

.add-device-button-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 24px 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  box-sizing: border-box;
}

.add-device-button-container>.secondary-button {
  background-color: transparent;
  border: none;
  opacity: 80%;
}

.add-device-button-container>.secondary-button:hover {
  opacity: 100%;
  border: none;
  box-shadow: none;
}

.disabled {
  user-select: none;
  opacity: 0.15;
}

.disabled:hover {
  opacity: 0.15;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.modal-bg {
  background: rgba(2, 3, 15, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
}

.modal {
  padding: 56px 64px;
  background: #14025C;
  box-shadow: 0px 0px 8px rgba(12, 19, 79, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  max-height: 90vh;
}

.preview-parameters-modal {
  width: 30vw;
}

.add-device-modal,
.pair-device-modal {
  width: 40vw;
}

.privacy-confirmation-modal {
  width: 50vw;
}

.pair-device-modal {
  height: 440px;
  justify-content: flex-start;
}

.pair-device-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 24px;
}

.result-animation-wrapper>svg {
  width: 140px;
  display: block;
  margin: 0px auto;
}

.etherscan {
  color: #FF114F;
  font-family: 'JetBrains Mono', monospace;
}

.privacy-text {
  width: 100%;
  color: #F1EBFF;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.6rem;
  margin-top: 32px;
  max-height: 500px;
  overflow-y: scroll;
}

.result-animation-wrapper.success>svg {
  filter: drop-shadow(0 0 16px #04CB00bb);
}

.result-animation-wrapper.fail>svg {
  filter: drop-shadow(0 0 16px #D80000bb);
}

.result-animation-wrapper.warning>svg {
  filter: drop-shadow(0 0 16px #E3880066);
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.path.circle {
  -webkit-animation: dash .9s ease-in-out;
  animation: dash .9s ease-in-out;
}

.path.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash .9s .35s ease-in-out forwards;
  animation: dash .9s .35s ease-in-out forwards;
}

.path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check .9s .35s ease-in-out forwards;
  animation: dash-check .9s .35s ease-in-out forwards;
}

.result-animation-wrapper>p{
  text-align: center;
  margin: 32px auto;
  color: #F1EBFFCC;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 2rem;
  max-width: 420px;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

.pair-devices-loaders {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
}

.shimmer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
}

.shimmer-container.height-small>.shimmer-bg {
  height: 86px;
}

.shimmer-container.height-large>.shimmer-bg {
  height: 130px;
}

.shimmer-bg {
  width: 100%;
  opacity: 90%;
  border: 1px solid #F1EBFF22;
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: linear-gradient(270deg, rgba(241, 239, 255, 0) 0%, rgba(241, 239, 255, 0.1) 45.31%, rgba(241, 239, 255, 0) 100%);
}

.shimmer-bg:nth-child(2) {
  opacity: 70%;
}

.shimmer-bg:nth-child(3) {
  opacity: 50%;
}

.shimmer-bg:nth-child(4) {
  opacity: 30%;
}

.shimmer-bg:nth-child(5) {
  opacity: 10%;
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }

  100% {
    background-position: 100% 0;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.user-types-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pair-radio-button {
  cursor: pointer;
  width: 160px;
  height: 180px;
  box-sizing: border-box;
  border: 1px solid #F1EBFF33;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #F1EBFF;
  font-family: 'Roboto', sans-serif;
}

.pair-radio-button.checked {
  border-color: #3204EB;
  box-shadow: 0 0 8px #3204EB66;
}

.pair-radio-button:hover {
  border-color: #3204EB;
  box-shadow: 0 0 8px #3204EB66;
}

.pair-radio-button>input {
  width: 0;
  height: 0;
  opacity: 0;
}

.profile-settings-modal {
  width: 50vw;
}

.profile-settings-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.profile-settings-content>.grid1 {
  grid-area: 1 / 1 / 2 / 3;
}

.profile-settings-content>.grid2 {
  grid-area: 2 / 1 / 3 / 2;
}

.profile-settings-content>.grid3 {
  grid-area: 2 / 2 / 3 / 3;
}

.profile-settings-content>.grid4 {
  grid-area: 3 / 1 / 4 / 2;
}

.profile-settings-content>.grid5 {
  grid-area: 3 / 2 / 4 / 3;
}

.modal-header {
  width: 100%;
}

.modal-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  margin: 0;
  text-align: left;
  color: #F1EBFF;
}

.modal-content {
  width: 100%;

}

.close-modal {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.close-modal>svg {
  width: 24px;
  height: 24px;
  color: #F1EBFF70;
}

.close-modal:hover>svg {
  color: #F1EBFF;
}

.toastPrototype {
  max-width: 450px;
  max-height: 400px;
  position: fixed;
  right: 0;
  bottom: 0;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  z-index: 9;
  line-height: 16px;
  overflow-wrap: break-word;

  font-size: 14px;
}

.error {
  color: #07030E;
  padding: 24px;
  background-color: #8A0029;
  border-radius: 8px;
  margin: 24px;
}

.info {
  color: #fff;
  padding: 24px;
  background-color: #5611EA;
  border-radius: 8px;
  margin: 24px;
}

.badge {
  padding: 2px 12px;
  border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #F1EBFF;
  display: inline;
  text-transform: capitalize;
  white-space: nowrap;
  user-select: none;
}

.badge-purple {
  background-color: #3204EB;
}

.badge-pink {
  background-color: #FF114F;
}

.badge-light-purple {
  background-color: #7904EB;
}

.badge-green {
  background-color: #04CB00;
}

.badge-red {
  background-color: #D80000;
}

.badge-yellow {
  background-color: #E38800;
}

.badge-grey {
  background-color: #585858;
}

.reports-dropdown-container .css-b62m3t-container {
  width: 240px;
}

.css-b62m3t-container {
  text-align: start;
  width: 180px;
  z-index: 5;
}

.css-zc4rpc-control {
  min-width: 100%;
}

.filter-toggle-container .css-b62m3t-container {
  margin-right: -36px;
}

.chart-button-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.bell-animate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.bell-animate>p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #F1EBFF;
  margin: 0 0 12px;
}

.bell-animate>svg {
  display: block;
  font-size: 300px;
  height: 0.5em;
  width: 0.5em;
}

.too-big-actually {
  transform-origin: 9.5px 9.5px;
  transform: scale(0.5);
}

.bell-whole {
  animation: ring 20s linear infinite;
  transform-origin: 9.5px 2.4781px;
}

.bell-part {
  fill: #F1EBFF;
}

.bell-part--ringer {
  animation: ding 20s linear infinite;
}

@keyframes ring {

  0% {
    transform: rotate(0deg);
  }

  1.5% {
    transform: rotate(30deg);
  }

  2.5% {
    transform: rotate(-25deg);
  }

  3.75% {
    transform: rotate(20deg);
  }

  5.15% {
    transform: rotate(-10deg);
  }

  6.65% {
    transform: rotate(5deg);
  }

  8.0% {
    transform: rotate(-2deg);
  }

  10.0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }

}

@keyframes ding {

  0% {
    transform: translateX(0);
  }

  1.2% {
    transform: translateX(4px);
  }

  1.5% {
    transform: translateX(4px);
  }

  2.3% {
    transform: translateX(-4px);
  }

  2.5% {
    transform: translateX(-4px);
  }

  3.55% {
    transform: translateX(4px);
  }

  3.75% {
    transform: translateX(4px);
  }

  5.45% {
    transform: translateX(-3px);
  }

  7.15% {
    transform: translateX(2px);
  }

  9.0% {
    transform: translateX(-1px);
  }

  11.0% {
    transform: translateX(0);
  }

  100% {
    transform: rotate(0deg);
  }

}

@media only screen and (max-width: 88rem) {
  .landing-about {
    padding: 64px;
  }

  .content-table>thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .content-table tr {
    border-top: 1px solid rgba(241, 239, 255, 0.6);
    display: block;
    margin-bottom: 1rem;
    margin-bottom: 32px;
  }

  .content-table td {
    border-bottom: 1px solid rgba(241, 239, 255, 0.2);
    display: block;
    text-align: end;
    color: rgba(241, 239, 255, 0.8);
  }

  .content-table td:last-of-type {
    border: none;
  }

  .content-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: 600;
    color: rgba(241, 239, 255);
    margin-right: 96px;
  }

  .main-content>.col-container:first-of-type {
    width: 80%;
  }

  .col-container#accessStatus {
    display: none;
  }

  .detail-content>.info-column>#accessStatusMobile {
    display: flex;
    margin-right: 32px;
  }

  .device-box-table {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .pair-devices-loaders>div:nth-of-type(2) {
    display: none;
  }

  .preview-parameters-modal {
    width: 50vw;
  }
  
  .table-body-row>td.table-action-buttons {
    text-align: right;
  }  
}

@media only screen and (max-width: 64rem) {
  .main-content-wrapper {
    width: calc(100vw - 72px);
    margin-left: 72px;
  }

  .section>.inline {
    flex-direction: column-reverse;
  }

  .section>.inline.reverse {
    flex-direction: column-reverse;
  }

  .section .landing-illustration>img {
    max-height: 400px;
  }

  .landing-text>h1 {
    font-size: 32px;
    text-align: center;
  }

  .landing-text>p {
    font-size: 18px;
    line-height: 2.2rem;
    text-align: center;
  }

  .sidepanel-wrapper {
    min-width: 72px;
    width: 72px;
    padding: 24px 14px;
  }

  .sidepanel-header {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
  }

  .sidepanel-header>h1 {
    display: none;
  }

  .profile-container {
    display: none;
  }

  .sidepanel-header>.profile-container {
    display: block;
  }

  .sidepanel-header>.profile-container>.profile-button {
    max-width: 44px;
    max-height: 44px;
  }

  .sidepanel-header>.profile-container>.profile-button>div {
    height: 40px !important;
    min-width: 40px !important;
  }

  .sidepanel-header>.profile-container>.profile-dropdown {
    top: -22px;
    left: 70px;
    z-index: 5;
  }

  .sidepanel-button {
    justify-content: center;
  }

  .sidepanel-button>p {
    display: none;
  }

  .modal {
    width: 70vw;
  }

  .sidepanel-notification {
    right: 4px;
    top: -10px;
  }

}

@media only screen and (max-width: 50rem) {

  .content-table,
  .shimmer-container {
    margin-top: 96px;
  }

  .navbar-wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }

  .content-header-container {
    padding: 32px;
  }

  .header-container {
    gap: 24px;
  }

  .navbar-container {
    white-space: nowrap;
  }

  .navbar-container>ul .nav-item {
    padding: 0 12px;
  }

  .header-container>.landing-header {
    font-size: 36px;
    line-height: 3rem;
  }

  .header-container>.landing-subheader {
    font-size: 18px;
  }

  .landing-content {
    gap: 32px;
  }

  .landing-button {
    font-size: 14px;
    width: 240px;
  }

  .report-information.device-box {
    flex-direction: column;
  }

  .main-content-wrapper {
    padding: 32px;
  }

  .device-item>.detail-content {
    flex-direction: column;
    gap: 24px;
  }

  .detail-content>.info-column {
    width: 100%;
  }

  .detail-content>.info-column:last-of-type {
    align-items: flex-start;
  }

  .detail-content .prop-content {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 38rem) {
  .header-container {
    gap: 16px;
  }

  .header-container>.landing-header {
    font-size: 30px;
    line-height: 2.4rem;
  }

  .header-container>.landing-subheader {
    font-size: 14px;
  }

  .landing-content {
    gap: 24px;
  }

  .landing-button {
    font-size: 14px;
  }

  .main-content-wrapper {
    padding: 24px;
  }

  .content-header-container:not(.alerts-header-container) {
    flex-direction: column;
  }

  .content-header-container>.content-header {
    width: 100%;
    text-align: left;
  }

  .modal {
    width: 90vw;
    padding: 32px 24px;
  }

  .add-device-button-container>button {
    min-height: 36px;
  }

  .result-animation-wrapper>p {
    font-size: 18px;
  }

  .device-box {
    flex-direction: column;
  }

  .device-box-column.col2 {
    align-items: flex-start;
  }

  .device-box-column#icon-container>svg:nth-of-type(1) {
    display: none;
  }

  .device-box-column#icon-container>svg:nth-of-type(2) {
    display: initial;
  }

  .pause-box {
    flex-direction: column;
  }
}

@media only screen and (max-width: 25rem) {
  .navbar-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .landing-about {
    padding: 32px;
  }

  .section .landing-illustration>img {
    max-height: 220px;
  }

  .landing-text>h1 {
    font-size: 24px;
    text-align: center;
    margin: 16px 0;
  }

  .landing-text>p {
    font-size: 14px;
    line-height: 1.3rem;
    text-align: center;
  }

  .header-container>.landing-header {
    font-size: 26px;
  }

  .main-content-wrapper {
    width: calc(100vw - 48px);
  }

  .sidepanel-wrapper {
    min-width: 48px;
    width: 48px;
    padding: 24px 8px;
  }

  .sidepanel-header-icon {
    display: block;
    width: 28px;
  }

  .sidepanel-button {
    justify-content: center;
    padding: 0px 16px;
  }

  .sidepanel-button-icon {
    width: 14px;
  }

  .main-content-wrapper {
    margin-left: 48px;
  }

  .sidepanel-notification {
    display: none;
  }

  .sidepanel-header>.profile-container>.profile-button {
    max-width: 32px;
    max-height: 32px;
  }

  .sidepanel-header>.profile-container>.profile-button>div {
    transform: scale(0.7);
  }

  .sidepanel-header>.profile-container>.profile-dropdown {
    top: -22px;
    left: 48px;
    z-index: 5;
  }

  .profile-settings-content {
    display: flex;
    flex-direction: column;
  }
}

#ciphermedLogo {
  user-select: none;
  cursor: pointer;
}

.preview-mail-modal {
  background: radial-gradient(138.09% 162.46% at 100% 0%, #0C134F 19.48%, #090D35 33.85%, #080C33 63.11%, #010104 100%);
}

.is-field-empty {
  border-bottom: 1px solid #D80000 !important;
}